<template>
  <a-modal
    title="Редактировать пользователя"
    v-model:visible="modalVisible"
    :confirm-loading="confirmLoading"
    :closable="false"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="user" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-form-item label="Имя пользователя">
          <a-input
            ref="userName"
            v-model:value="user.username"
            placeholder="Введите имя пользователя"
          />
        </a-form-item>
        <a-form-item label="Email адрес">
          <a-input
            v-model:value="user.email"
            placeholder="Введите email адрес"
          />
        </a-form-item>
        <a-form-item label="Номер телефона">
          <a-input
            @input="updatePhone($event.target.value)"
            :value="phone"
            v-maska="'+998(##) ###-##-##'"
            placeholder="Введите номер телефона"
          />
        </a-form-item>
        <a-form-item label="Роль">
          <a-select
            ref="role"
            show-search
            placeholder="Выберите роль"
            v-model:value="user.role"
            :options="roles"
            :filter-option="filter"
            @change="updateRole"
          />
        </a-form-item>
        <a-form-item label="Филиал" v-if="['manager', 'cashier'].includes(userRole)">
          <a-select
            ref="store_id"
            show-search
            mode="multiple"
            placeholder="Выберите филиал"
            v-model:value="user.stores"
            :options="stores"
            :filter-option="filter"
          />
        </a-form-item>
        <a-form-item label="Бренд" v-if="userRole === 'brandmanager'">
          <a-select
            ref="brand_id"
            show-search
            mode="multiple"
            placeholder="Выберите бренд"
            v-model:value="user.brands"
            :options="brands"
            :filter-option="filter"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, toRaw, nextTick, reactive} from "vue";
import User from "@/models/User";

const props = defineProps({
  userId: {
    type: Number,
    default: 0,
  },
  roles: {
    type: Object,
    default() {
      return {}
    },
  },
})

const emit = defineEmits(['hide-modal', 'reload'])

let fetching = ref(true),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  userName = ref("userName"),
  labelCol = {span: 9},
  phone = ref(''),
  wrapperCol = {span: 13},
  stores = ref([]),
  brands = ref([]),
  userRole = ref(''),
  user = reactive(new User({}));

const
  filter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phone.value = input
    user.phone = x[0];
  },
  updateRole = (role) => {
    userRole.value = role
  },
  resetForm = () => {
    Object.assign(user, new User({}))
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(user)

    return apiClient
      .put(`/admin/update?id=${props.userId}`, model)
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible = false;
        confirmLoading = false;
        emit('reload')
        emit('hide-modal')
        // console.log(err)
      }).finally(() => {
        confirmLoading = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/store/fetch`).then(({data}) => {
  stores.value = data.data
})
apiClient.get(`/brand/fetch`).then(({data}) => {
  brands.value = data.data
})
apiClient.get(`/admin/${props.userId}`)
  .then(response => {
    if (response) {
      let model = new User(response.data.data.user)
      updatePhone(model.phone)
      model.role = model.role.toString()
      Object.assign(user, model)
      userRole.value = user.role
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    fetching.value = false;
    nextTick(() => userName.value?.focus());
  })
</script>

<style scoped>
.spin-loader {
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0 20px;
}
</style>
