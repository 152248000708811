<template>
  <a-button class="editable-add-btn" @click="openModal" type="primary" style="margin-bottom: 16px">Добавить пользователя</a-button>
  <a-modal
    title="Добавить пользователя"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="confirmLoading"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="user" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Имя пользователя">
        <a-input
          ref="userName"
          v-model:value="user.username"
          placeholder="Введите имя пользователя"
        />
      </a-form-item>
      <a-form-item label="Email адрес">
        <a-input
          v-model:value="user.email"
          placeholder="Введите email адрес"
        />
      </a-form-item>
      <a-form-item label="Номер телефона">
        <a-input
          @input="updatePhone($event.target.value)"
          :value="phone"
          v-maska="'+998(##) ###-##-##'"
          placeholder="Введите номер телефона"
        />
      </a-form-item>
      <a-form-item label="Пароль">
        <a-input
          type="password"
          v-model:value="user.password_field"
          placeholder="Введите пароль"
        />
      </a-form-item>
      <a-form-item label="Проверка пароля">
        <a-input
          type="password"
          v-model:value="user.password_confirm"
          placeholder="Введите пароль"
        />
      </a-form-item>
      <a-form-item label="Роль">
        <a-select
          ref="role"
          show-search
          placeholder="Выберите роль"
          v-model:value="user.role"
          :options="roles"
          :filter-option="filter"
        />
      </a-form-item>
      <a-form-item label="Филиал" v-if="['manager', 'cashier'].includes(user.role)">
        <a-select
          ref="store_id"
          show-search
          mode="multiple"
          placeholder="Выберите филиал"
          v-model:value="user.stores"
          :options="stores"
          :filter-option="filter"
        />
      </a-form-item>
      <a-form-item label="Бренд" v-if="user.role === 'brandmanager'">
        <a-select
          ref="brand_id"
          show-search
          mode="multiple"
          placeholder="Выберите бренд"
          v-model:value="user.brands"
          :options="brands"
          :filter-option="filter"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, reactive, toRaw, nextTick} from "vue";
import { notification } from "ant-design-vue";
import NewUser from "@/models/NewUser";

const props = defineProps({roles: {
    type: Object,
    default() {
      return {}
    },
  },
})

const emit = defineEmits(['reload'])

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  userName = ref("userName"),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  stores = ref([]),
  brands = ref([]),
  phone = ref(''),
  user = reactive(new NewUser({}));

const
  filter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => userName.value?.focus());
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phone.value = input
    user.phone = x[0];
  },
  resetForm = () => {
    Object.assign(user, new NewUser({}))
    modalVisible.value = false;
  },
  create = () => {
    if (user?.password_field !== user?.password_confirm) {
      notification.warning({
        message: 'Пароли не совпадают',
      })
      return;
    }
    confirmLoading.value = true;

    let model = toRaw(user);

    return apiClient
      .post('/admin', model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      })
  };

apiClient.get(`/store/fetch`).then(({data}) => {
  stores.value = data.data
})

apiClient.get(`/brand/fetch`).then(({data}) => {
  brands.value = data.data
})

</script>
