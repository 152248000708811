export default function NewUser(attrs) {
  this.username = attrs.username ? attrs.username : ''
  this.email = attrs.email ? attrs.email : ''
  this.phone = attrs.phone ? attrs.phone : ''
  this.role = attrs.role ? attrs.role : null
  this.password_field = attrs.password_field ? attrs.password_field : ''
  this.password_confirm = attrs.password_confirm ? attrs.password_confirm : ''
  this.stores = attrs.stores ? attrs.stores : []
  this.brands = attrs.brands ? attrs.brands : []
}
